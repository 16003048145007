.loading {
    position: fixed;
    top: 50%;
    left: 50%;
    fill: #ab47bc !important;
    margin-left: -32px;
    margin-top: -32px;
    z-index: 99999;
  }
  .loader_section_::before{
    content: "";
    position: fixed;
    left: 0;
    top:0;
    width: 100%;
    height: 100vh;
    background: rgba(245, 242, 242, 0.25);
    z-index: 1500;
    overflow: hidden;
  }

  .WithReq{
    width: 20%;
  }

  @media screen and (max-width:480px) {
    .WithReq{
      width: 100% !important;
    }
  }


  @media screen and (max-width:768px) {
    .WithReq{
      width: 100% !important;
    }
  }
  @media screen and (max-width:992px) {
    .WithReq{
      width: 100% !important;
    }
  }

